export enum LS {
  CART = "CART",
  SHOW_DEFAULTER_MESSAGE = "SHOW_DEFAULTER_MESSAGE",
  PROFILE = "PROFILE",
  USER = "USER"
}

export const IMAGE_ENDPOINT = process.env.NEXT_PUBLIC_IMAGE_ENDPOINT;

export const qp = {
  PRODUCT: "product",
  PROMOTION: "promotion",
  PROMOTION_TYPE: "promotion-type",
  CATEGORY: "category",
  BRAND: "brand",
  SEARCH: "search",
  SUBCATEGORIES: "subcategories",
  BRANDS: "brands"
};

export const qpBack = "back";

export const qpJoin = "_JOIN_";

export enum FacetType {
  BRANDS = "brands",
  SUBCATEGORIES = "subcategories"
}
